@import '../../styles/main.scss';
.container{
    margin-top: 5rem!important;
    width:80%;
    justify-content: space-evenly;
}
.dashboard-grid {
    background-color: #F2F2F2;
}


.mycard {
    background-color: red;
    height: 200px;
    text-align: center;
    align-items: center;
}


.admin-dashboard-card {
    background-color: rgb(245, 205, 237);
    width: 500px!important;
    padding: 0px 40px ;
    margin-top: 40px;
    &-content{
        &-icon {
            display: flex;
            justify-content: center;
            font-size: 50px;
        }
    }

}

.admin-dashboard-manage-role-btn {
    background: rgb(138, 70, 124);
    color: white;
    &:hover{
    background: rgb(139, 2, 112) !important;
    color: white !important;
    border-color: white !important;
    }
}

.admin-dashboard-divider {
    border-top: 1px solid #8BAAB1;

}

.content-container {
    width: 90%;
    margin: 1rem auto;
}

.admin-survey-card {
    padding: 10px;
    font-family: sans-serif;
    height: 300px;
    h3{
        font-weight: lighter;
    }
}
.card-container {
    display: flex;
    justify-content: space-around;
}

.assign-survey-btn {
    width: 200px;
    border: 1px solid black;
    font-size: 16px;
    margin-top: 20px;
}

.admin-assignsurvey-card {
    &:hover{
        cursor: default !important;
    }
}

.workflow-search {
    width: 250px;
}

.create-workflow-btn {
    background: green;
    border-color: black; 
}

// not working , reverting to inline css
.create-workflow-modal {
    top: 20 !important;
}

.admin-dashboard-card-container {
    overflow-y: scroll;
    height:420px;
}

.admin-dashboard-workflow-card {
    height: 50px; 
    width: 50px;
    margin-top:40px;
}

// this property isn't working even after adding marking important , reverting this to inline css for backup
.admin-dashboard-card-ellipse {
    position: absolute !important;
    top: 10 !important; 
    right: 10 !important;
    cursor: pointer !important;
}
.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Change the alpha value to control transparency */
    z-index: 9999; /* Adjust the z-index value to make sure it's above other content */
  }
  

.approve-survey-content-container {
  height: 85vh;
}
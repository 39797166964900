
.parent_grouping {
    border: solid 1px #d9d1d1;
    .extra_chile_group_text_font {
      width: fit-content;
      margin-top: -10px !important;
      margin-left: 20px;
      background: #fff;
      font-size: 12px;
    }
    .extra_no_child {
      // padding: 10px;
      margin: 10px;
    }
  }
  
.form-dynamic-content {
    margin-top: 15px;
}
.add-field-btn-container {
  display: flex;
  // width: 100%;
  justify-content: flex-end;
  align-items: center;
}
.add-field-btn {
  width: 200px !important;
  height: 40px;
}

.submit-btn-create-survey {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.signature-image-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.signature-image-container {
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.signature-lazy-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.user-dashboard-greeting {
    font-size: 2rem;
	color: #043E5B;
}
.user-dashboard-icons {
    height: 150px;
    width: 150px;
}

.submit-survey-card,.approve-survey-card ,.view-approved-survey-card  {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
}
.dashboard-p-font {
    font-size: 18px;
    padding-left: 20px;
}

.submit-survey-btn {
    // background: rgb(138, 70, 124);
    background-color: #825cff;
    color: white;
    &:hover{
    // background: rgb(139, 2, 112) !important;
    background-color: #693ef8 !important;

    color: white !important;
    border-color: white !important;
    }
}
.approve-survey-btn {
    // background: rgb(54, 112, 113);
    background-color: #825cff;
    &:hover{
        // background: rgb(1, 101, 103) !important;
        background-color: #693ef8 !important;
        color: white !important;
        border-color: white !important;
    }
    
}

.view-approved-survey-btn {
    // background-color: rgb(3, 186, 6);
    background-color: #825cff;
    &:hover{
        // background-color: rgb(2, 112, 4) !important;
        background-color: #693ef8 !important;
        color: white !important;
        border-color: white !important;
    }
}

.user-dashboard-greeting-container {
    display:flex;
    justify-content:space-between;
    align-items:center;
    background-color:#E4DEF7;
    padding:10px;
    border-radius:5px;
    margin:1rem 0;
}
.externaluser-action-btns-mobile {
    display: none!important;
}

// uninvited user dashboard page
@media screen and (max-width:700px) {
    .externaluser-action-btns {
        display: none!important;
    }
    .externaluser-action-btns-mobile {
        display: block!important;
    }
}

.userdashboard-main-row {
    height: 80%;
    overflow-y: scroll !important;
}

.h-70vh {
    height: 70vh;
}
@import '../../styles/main.scss';
.header {
    height: 10vh;
    // background-color: #0C0C9A;
    // background-color: #002060;
    background-color: #002147;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0rem 1rem;
    // border-bottom: 2px solid black;
    &-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &-left {
        text-align: right;
    }
    &-left-loggedin{
        display: flex;
        // width: 40%; 
        justify-content: space-between;
        align-items: center;
        &-tokencount {
            width: 150px;
            background-color: white;
            height: 8vh;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 2rem;
            &-balance {
                font-size: 20px;
                padding: 0 5px;
            }
            &-svg {
                border-left: 2px #8BAAB1 solid;
            }
        }
    }
}
.brand-logo {
    width:220px;
    // height: 200px;
    cursor: pointer;
}
.user-avatar {
    cursor: pointer;
}

.dashboard-btn {
    width:200px;
    height: 40px;
    background-color: #FFAE55;
    font-size: 20px;
    font-weight: bold;
    &:hover {
        color:black!important;
    }
}

@media screen and (max-width:900px) {
    .brand-logo {
        width: 150px;
    }
}
.userdata-popover {
    width: 300px;
  
    @media (max-width: 680px) {
      width:280px;
    }
    @media (max-width: 400px) {
        width:260px;
    }
}

.login-btn,.logout-btn {
    background-color: #7A6BF8;
}
.logout-btn {
    color:#ffffff;
    font-weight:bold;
}

.popover-height-onspin {
    height: 150px;
}

.user-role {
    font-weight: lighter;
    color: #ffffff;
}

.admin-link-dropdown {
    position: relative;
    padding-left: 20px;
    bottom: 3px;
}

.blockchain-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
}
@import '../../styles/main.scss';
.view-uploaded-survey {
    width:300px;
    height: 40px;
    background-color: #FFAE55;
    font-size: 20px;
    // font-weight: medium;
    &:hover {
        color:black!important;
    }
}
.create-survey-main {
    width:90%;
    margin:0 auto;
    padding:10px;
    background-color:#ffffff;
    margin-top:10px;
    height: 50vh;
    &-area {
        display:flex;
        justify-content:space-between;
        align-items:center;
        &-btn {
            background-color:green;
        }
        // &-table{
        //     max-height: 45vh;
        // }
    }

    
}
.assign-submitter-main {
    background-color:#ffffff;
    margin-top:10px;
    padding:10px;
    width:90%;
    margin:0 auto;
}
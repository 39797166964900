.signature-canvas {
    background-color: rgb(214, 213, 212);
    width: 100%;
    height: 150px;
}
.e-signature-card {
    width: 100%;
    height: 300px;
}
.typed-signature {
    font-size:60px;
    margin-top: 20px; 
    border: 1px solid black;
    padding: 10px;
    font-family: Qwitcher Grypen, cursive;
    font-weight:700;
    height:120px;
    overflow:hidden;
}
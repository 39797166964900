.homepage {
	// width: 98%;
	// margin: 0 auto;

	width: 100%;
	padding: 0 2rem;

	&-svg-container {
		width: 400px;
		display: flex;
		padding: 0px!important;
		justify-content: space-between;
		padding-top: 5rem!important;
		padding-bottom: 1rem!important;
	}
	&-left {
		display: flex;
		justify-content: center;
		align-items: center;
		&-h1 {
			&-main {
				font-size: 4rem;
				color: #0C0C9A;
				font-weight: bold;
			}
		}
		&-p {
			font-size: 1.5rem;
			// color: #043E5B;
			color: #0C0C9A;
			&-btn {
				width:200px;
				height: 50px;
				padding: 5px;
				// background-color: #FDBB41;
				background-color: #7A6BF8;
				font-size: 20px;
				font-weight: bold;
				// color: #043E5B;
				color: #ffffff;
				&:hover {
					color:#043E5B!important;
				}
			}
		}
	}
	&-right {
		// background-color: yellow;
		display: flex;
		justify-content: center;
		align-items: start;
		&-img {
			width: 100%;
			// height: 80%;
			max-height: 600px;

		}
	}
}
.homepage-left {
	order: 2;
  }
  
.homepage-right {
	order: 1;
}
  
@media (min-width: 992px) {
	.homepage-left {
	  order: 1;
	}
  
	.homepage-right {
	  order: 2;
	}
}
  
@media (max-width:992px) {
	.homepage {
		&-svg-container {
			display: flex;
			justify-content: space-around;
			width: 100%;
		}
		&-left-h1-main {
			font-size: 3rem;
		}
		&-left-p {
			font-size: 1rem;
		}
	}
}
@media (max-width:690px) {
	.homepage {
		&-left-h1-main {
			font-size: 2.5rem;
		}
	}
}

//   unused css styles 

// .container {
	
// 	width: 80%;
// 	margin: auto;
// 	padding: 0 2rem;
// }

// .main {
// 	min-height: 100vh;
// 	padding: 4rem 0;
// 	flex: 1;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;
// }

// .title {
// 	line-height: 1.15;
// 	font-size: 3rem;
// 	text-align: center;
// 	margin: 50px;
// }

// .title a {
// 	color: #0070f3;
// 	text-decoration: none;
// }

// .grid {
// 	display: flex;
// 	align-items: center;
// 	flex-direction: column;
// }

// .card {
// 	margin: 0.5rem;
// 	padding: 0.7rem;
// 	text-align: center;
// 	color: #0070f3;
// 	background-color: #fafafa;
// 	text-decoration: none;
// 	border: 1px solid #0070f3;
// 	border-radius: 10px;
// 	transition: color 0.15s ease, border-color 0.15s ease;
// 	width: 100%;
// }

// .card:hover,
// .card:focus,
// .card:active {
// 	cursor: pointer;
// 	background-color: #f1f1f1;
// }

// .footer {
// 	display: flex;
// 	flex: 1;
// 	padding: 2rem 0;
// 	border-top: 1px solid #eaeaea;
// 	justify-content: center;
// 	align-items: center;
// 	margin-top: 10rem;
// }

// .footer a {
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	flex-grow: 1;
// }

// .logo {
// 	height: 1.5rem;
// 	margin-left: 0.5rem;
// }

// @media (max-width: 1200px) {
// 	.container {
// 		width: 100%;
// 	}
// }

// .flex-container {
// 	display: flex;
// 	flex-flow: row wrap;
// }

// .flex-container > div {
// 	width: 100px;
// 	margin: 10px;
// 	text-align: center;
// 	line-height: 75px;
// 	font-size: 30px;
// }

// #console {
// 	width: 100%;
// 	height: 100%;
// 	overflow: auto;
// 	word-wrap: break-word;
// 	font-size: 16px;
// 	font-family: monospace;

// }

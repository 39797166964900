@import "./mixins";

.center {
  @include center();
}

.externaluser-historytable-spincontainer {
  height: 400px;
}

.externaluser-historytablestyle {
  max-height: 400px;
  overflow-x: auto;
  overflow-y: auto;
}

.flex {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex-direction-col {
  @include flexWithDirection(column);
}
.antd-form-button-container {
  // float is working for revoke roles form only
  // float: right;
  // below one works for revoke roles form and assign workflow form as well
  display: flex;
  justify-content: end;
}

.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-start-center {
  display: flex;
  justify-content: start;
  align-items: center;
}
.flex-end-center {
  display: flex;
  justify-content: end;
  align-items: center;
}
.flex-direction-col-only {
  display: flex;
  flex-direction: column;
}

.flex-center-direction-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.flex-and-align-center {
  display: flex;
  align-items: center;
}
.flex-and-justify-right {
  display: flex;
  justify-content: right;
}
.flex-end-center {
  display: flex;
  justify-content: end;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-around-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.invitation-form {
  max-width: 600px;
}
.externaluser-tokenhistorytable {
  max-height:400px;
  overflow-x:auto;
  overflow-y:auto;
}
.flex-and-align-center {
  @include flexAndAlignItems(center);
}
.textalign-center {
  text-align: center;
}
.green-black-btn {
  background-color: green;
  border-color: black;
}
.red-black-btn {
  background-color: red;
  border-color: black;
}
.brown-black-btn {
  background-color: brown;
  border-color: black;
}
.blue-black-btn {
  background-color: blue;
  border-color: black;
}
.align-items-center {
  align-items: center;
}
.workflow-info {
  display: grid;
  grid-template-columns: auto ;
  gap: 10px;
  background-color: #FCEE94;
  padding: 20px;
}
.workflow-info-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}
.workflow-info-key {
  font-size:20px;
  font-weight: light;
}

.workflow-info-value {
  font-weight: light;
}
.workflows-select-filter {
  width: 200px;
}
.workflow-card-icon {
  height: 50px;
  width: 50px;
}
.workflow-card {
  height: 200px;
}
.workflow-reject-comment-textarea {
  height: 150px !important;
  resize: none !important;
}
.width-height-100perc {
  width: 100%;
  height: 100%;
}
.bold-font {
  font-weight: bold;
}
.cards-container {
  overflow-y: scroll;
  height:480px;
}
.width-100perc {
  width: 100%;
}
.height-250 {
  height: 250px !important;
}
.height-400 {
  height: 400px !important;
}
.height-500 {
  height: 500px !important;
}
.w-100perc{
  width: 100%;
}
.height-100perc {
  height: 100%;
}
.w-220 {
  width: 220px;
}

.opacity-point8{
  opacity: 0.8;
}

.font-18px {
  font-size: 18px;
}

.display-flex {
  display: flex;
}
.mt-1rem {
  margin-top: 1rem;
}

.mt-2rem {
  margin-top: 2rem;
}

.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}
.mt-5 {
  margin-top: 50px;
}
.mt-6 {
  margin-top: 60px;
}

.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mb-4 {
  margin-bottom: 40px;
}
.mb-5 {
  margin-bottom: 50px;
}
.mb-6 {
  margin-bottom: 60px;
}

.ml-1 {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 20px;
}
.ml-3 {
  margin-left: 30px;
}
.ml-4 {
  margin-left: 40px;
}
.ml-5 {
  margin-left: 50px;
}
.ml-6 {
  margin-left: 60px;
}

.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 20px;
}
.mr-3 {
  margin-right: 30px;
}
.mr-4 {
  margin-right: 40px;
}
.mr-5 {
  margin-right: 50px;
}
.mr-6 {
  margin-right: 60px;
}

.pt-1 {
  padding-top: 10px;
}
.pt-2 {
  padding-top: 20px;
}
.pt-3 {
  padding-top: 30px;
}
.pt-4 {
  padding-top: 40px;
}
.pt-5 {
  padding-top: 50px;
}
.pt-6 {
  padding-top: 60px;
}

.pb-1 {
  padding-bottom: 10px;
}
.pb-2 {
  padding-bottom: 20px;
}
.pb-3 {
  padding-bottom: 30px;
}
.pb-4 {
  padding-bottom: 40px;
}
.pb-5 {
  padding-bottom: 50px;
}
.pb-6 {
  padding-bottom: 60px;
}

.pl-1 {
  padding-left: 10px;
}
.pl-2 {
  padding-left: 20px;
}
.pl-3 {
  padding-left: 30px;
}
.pl-4 {
  padding-left: 40px;
}
.pl-5 {
  padding-left: 50px;
}
.pl-6 {
  padding-left: 60px;
}

.pr-1 {
  padding-right: 10px;
}
.pr-2 {
  padding-right: 20px;
}
.pr-3 {
  padding-right: 30px;
}
.pr-4 {
  padding-right: 40px;
}
.pr-5 {
  padding-right: 50px;
}
.pr-6 {
  padding-right: 60px;
}
.p-1rem {
  padding: 1rem;
}
.mx-1 {
  margin: 10px 0;
}
.mx-2 {
  margin: 20px 0;
}
.mx-3 {
  margin: 30px 0;
}
.mx-4 {
  margin: 40px 0;
}
.mx-5 {
  margin: 50px 0;
}
.mx-6 {
  margin: 60px 0;
}


.my-1 {
  margin: 10px 0;
}
.my-2 {
  margin: 20px 0;
}
.my-3 {
  margin: 30px 0;
}
.my-4 {
  margin: 40px 0;
}
.my-5 {
  margin: 50px 0;
}
.my-6 {
  margin: 60px 0;
}

.px-1 {
  padding: 10px 0;
}
.px-2 {
  padding: 20px 0;
}
.px-3 {
  padding: 30px 0;
}
.px-4 {
  padding: 40px 0;
}
.px-5 {
  padding: 50px 0;
}
.px-6 {
  padding: 60px 0;
}

.py-1 {
  padding: 0 10px;
}
.py-2 {
  padding: 0 20px !important;
}
.py-3 {
  padding: 0 30px;
}
.py-4 {
  padding: 0 40px;
}
.py-5 {
  padding: 0 50px;
}
.py-6 {
  padding: 0 60px;
}

.cio-dashboard-backpack-details-container {
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.tba-address {
    margin-bottom: auto !important;
}

.cio-dashboard-backpack-details-attribute-container {
    width:250px;
    border: 2px solid gray;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
}

.cio-dashboard-backpack-details-attribute-title {
    font-weight: bold;
}

.cio-dashboard-backpack-image-container {
    background-color: #ffffff;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // height:240px;
}

.cio-dashboard-backpack-image-container-spinner-container {
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cio-dashboard-backpack-details-piechart-card {
    margin-top: 1rem;
    width: 400px;
}
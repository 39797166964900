
@mixin center () {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

@mixin centerWithValue ($justifyCenter, $itemCenter, $width, $height) {
    display: flex;
    justify-content: $justifyCenter;
    align-items: $itemCenter;
    width:  $width;
    height: $height;
}

@mixin flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flexWithValue ($justifyCenter, $itemCenter) {
    display: flex;
    justify-content: $justifyCenter;
    align-items: $itemCenter;
}

@mixin flexWithDirection ($direction) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: $direction;
}

@mixin flexAndAlignItems($direction) {
    display: flex;
    align-items: $direction;
}

@mixin borderBottom($top) {
    border-bottom: 6px solid #000;
    width: 150px;
    border-radius: 20px;
    position: absolute;
    top: $top;
    left: 50%;
    transform: translate(-50%, -50%);
    border-top: 0;
  }
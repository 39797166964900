.grid-container {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: auto; /* Two columns with automatic width */
  grid-gap: 10px; /* Spacing between grid items */
  padding: 20px;
}

.grid-item {
  // display: flex;
  // align-items: center; /* Align label and value vertically in the middle */
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.label {
  font-weight: bold;
  margin-right: 5px; /* Add some space between label and value */
  width: 500px;
}
.approver-signatures-container {
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px; /* Adjust the gap between signatures as needed */
}

.each-approver-signature {
  border: 2px solid black;
  padding: 10px;
}
.surveyInfo {
  display: grid;
  grid-template-columns: auto ;
  gap: 10px;
  background-color: #FCEE94;
  padding: 20px;
}

.infoRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}
.infoRow div:last-child {
  justify-self: start;
}
.surveyValues {
  display: flex;
  justify-content: center;
  align-items: center;
}
.padded-input {
  padding: 10px;
}
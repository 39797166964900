.superadmin-dashboard-main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    &-buttons {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    &-transferownership-note {
        color: red;
        font-weight: 500;
    }
}


.superadmin-dashboard-main-space {
    display: flex;
    justify-content: flex-start; 
    align-items: center;
  }
  
@media screeen and (max-width: 500px) {
    .superadmin-dashboard-main-space {
      display: flex;
      justify-content: center; 
      align-items: flex-start; 
      flex-direction: column; 
    }
}  
  
@media screen and (max-width:680px) {
    .superadmin-dashboard-main {
        flex-direction: column;
        &-buttons {
            justify-content: space-between;
        }
    }
}
@media screen and (max-width:480px) {
    .superadmin-dashboard-main-buttons {
        flex-direction: column;
    }
}
.cio-dashboard-overview-card-title {
    text-align: center;
}

.cio-dashboard-metrics-container {
    height: 65vh;
    // height: 85vh;
    overflow-y: auto; // scroll nahi aara by default card container mein !!! , agar issue aega toh scroll use karlena
    overflow-x: hidden;
}

.card-container {
  position: relative;
  width: 280px;
  height: 200px;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.card-container.flipped {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-front {
  z-index: 2;
}

.card-back {
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
}

.card-back p {
  font-size: 16px;
  color: #000000;
}

.modelbackpack-card {
  height: 300px;
  display: flex;
  justify-content: center;
}

.cio-dashboard-backpack-img {
  transform: rotateY(0deg);
}
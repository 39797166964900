.stopwatch-container {
    text-align: center;
    // background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  .clock-icon-container {
    margin: 20px 0;
  }
  .session-clock-icon {
    font-size: 20px;
    color: #ffffff;
  }
  .remaining-time {
    font-size: 24px;
    padding-left: 10px;
    color: #ffffff;
  }
  
.sign-message-container {
    border: 1px solid #000; /* Add a border around the container */
    padding: 10px; /* Add padding for spacing */
    // width: 100%; /* Set a specific width for the container */
    position: relative; /* Set position to relative for positioning pseudo-element */
}

.sign-message {
    font-weight: bold; /* Make the message text bold */
    border-bottom: 1px solid #000; /* Add a border line under the message text */
    padding-bottom: 10px; /* Add some space below the message text */
}
.container-main {
    background-color: #f7f7fa;
    box-sizing: border-box;
    height: 85vh;
    padding: 0px 40px!important;
    width: 98%;
    margin: auto ;
    justify-content: center;
    align-items: center;
}

.survey-form-main {
    // background-color: #FCEE94;
    padding: 20px;
    max-height: 90%;
    overflow-y: auto;
    align-self: flex-start;
}

.left-column {
    border-right: 2px solid black;
    @media screen and (max-width:1200px) {
        border: none;
        border-bottom: 2px solid black;
    }
    height: 90%;
    padding-right: 20px;
    // display: flex;
    // flex-direction: column;
    justify-content: left;
    // align-items: center;
    &-title {
        font-size: 40px;
        // margin-top: 2rem;
        &>span {
            font-weight: lighter;
        }
    }
    &-desc {
        font-size: 20px;
        margin-top: 2rem;
        word-spacing: 3px;
        &>span {
            font-weight: lighter;
        }
    }
}


// .ant-input-number-affix-wrapper {
//     padding-inline-start:0px!important;
//     background-color: transparent!important;
//     color:blue!important;
// }

// refer for old css of container-main
// .container-main { // ye kaafi jagah use hora hai // before 14 june
//     background-color: #f7f7fa;
//     box-sizing: border-box;
//     height: 80vh;
//     padding: 40px;
//     width: 95%;
//     margin: 2rem auto;
//     justify-content: center;
//     align-items: center;
//     // overflow-y: scroll;
// }

.submit-workflow-row-main {
    height: 100%;
    align-items: center;
}
// .top-column {
//     background-color: #f0f0f0;
// }
.submit-survey-table .ant-table-header .ant-table-thead .ant-table-cell {
    // background-color: #F4BDE9;
    background-color: #fcee94;

}
.approve-survey-table .ant-table-header .ant-table-thead .ant-table-cell {
    // background-color: #C8FCFD;
    background-color: #fcee94;
}
.verify-survey-table .ant-table-header .ant-table-thead .ant-table-cell {
    // background-color: #C8FCFD;
    background-color: #fcee94;
}